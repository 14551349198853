import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const controlsSlice = createSlice({
    name: "controls",
    initialState: {
        play: true,
        muted: false,
        forcePlayNow: false,
    },
    reducers: {
        setPlay: (state, action: PayloadAction<boolean>) => {
            state.play = action.payload;
        },
        setForcePlayNow: (state, action: PayloadAction<boolean>) => {
            state.forcePlayNow = action.payload;
        },
        setMute: (state, action: PayloadAction<boolean>) => {
            state.muted = action.payload;
        },
    },
});

export const { setPlay, setMute, setForcePlayNow } = controlsSlice.actions;
export default controlsSlice.reducer;
