export enum SearchItemType {
  Video = "video",
  Pdf = "pdf",
  Html = "html",
  Sheet = "xls",
}

export enum SearchBarTheme {
  Mobile = "mobile",
  Desktop = "desktop",
  Widget = "widget",
}

export enum TopBarTheme {
  Video = "video",
  Pdf = "pdf",
  Sheet= "sheet",
}

export enum FileType {
  Video = "video",
  Pdf = "pdf",
  MsExcel = "application/vnd.ms-excel",
  mp4 = "video/mp4",
}

export enum SelectedDocumentType {
  All = "all",
  Document = "document",
  Video = "video",
  Presentation = "presentation",
  Table = "table",
}
