import { FunctionalComponent, h } from "preact";
import AppRouter from "./router";
import AppLogic from '../components/appLogic/index'; // Import the AppLogic component

import { Provider } from "react-redux";
import { store } from "../redux/store";
import SocketProvider from "../context/SocketContext";
import StreamProvider from "../context/StreamContext";
import { Auth0Provider } from "@auth0/auth0-react";
import {
  get_APP_URI_BASE,
  getAppPath,
  get_AUTH_HOST,
} from "../configs/env";

import { getIsEdge } from "../configs/env";

import { useEffect, useState } from "preact/hooks";
import { setJWTUserIdToken, setUserId } from "../redux/slices/globalsSlice";
import { useAppDispatch } from "../redux/hooks";

const MessageReceiver = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    const messageHandler = (event: any) => {
      // Security check: validate the origin of the message
      // check if the origin adheres to the format: https://*.korra.ai
      const originRegex = /^https:\/\/.*\.korra\.ai/;
      if (!originRegex.test(event.origin)) {
        return; // Ignore messages from untrusted origins
      }
      if (event.data.key && !!event.data.userId) {
        dispatch(setJWTUserIdToken(event.data.key));
        dispatch(setUserId(event.data.userId));
      }
    };

    window.addEventListener("message", messageHandler);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener("message", messageHandler);
    };
  }, []);

  return null;
};

let App: FunctionalComponent = () => {
  return (
    <div id="app">
      <Provider store={store}>
        <SocketProvider>
          <StreamProvider>
            <AppContent />
          </StreamProvider>
        </SocketProvider>
      </Provider>
    </div>
  );
};

function getWorkspacePortalAlias() {
  const segments = window.location.pathname.replace("/chatbot", "").split("/")

  if (segments.length < 3) {
    return "";
  }

  return "/" + segments.slice(1, 3).join("/");
}

const AppContent = () => {
  const [isReady, setReady] = useState(false);
  const [auth0Params, setAuth0Params] = useState<{
    redirect_uri: string;
    domain: string;
    connection?: string;
    audience?: string;
  }>();

  const [clientId, setClientId] = useState(process.env.AUTH0_CLIENT_ID as string)
  const [domain, setDomain] = useState(process.env.AUTH0_DOMAIN as string)

  useEffect(() => {
    getAuthorizationParams().then((params) => {
      if (!params) {
        return;
      }

      setAuth0Params(params as any);
      setReady(true);
    });
  }, [])

  async function getAuthorizationParams() {
    const path = getWorkspacePortalAlias();

    if (path.length !== 0) {
      const url = `${get_AUTH_HOST()}/portal?aliasKey=${path}&userId=1`;
      try {
        const response = await fetch(url, {
          headers: {
            "Content-Type": "application/json",
          },
          method: "GET",
          mode: "cors",
        }).then((res) => res.json());

        const { data } = response;
        if (data) {
          setClientId(data.clientId)
          setDomain(data.domain)

          if (data.connection) {
            return {
              redirect_uri: `${get_APP_URI_BASE()}${path}/login`,
              connection: data.connection,
            };
          } else {
            return {
              redirect_uri: `${get_APP_URI_BASE()}/login`,
              connection: data.connection,
            };
          }
        }
      } catch (e) {
        console.error(e)
      }
    }

    //setClientId("7APBHjJbT3SGhs2F4fin3pvT6IzhfCxi")
    setClientId(process.env.AUTH0_CLIENT_ID as string)
    setDomain(process.env.AUTH0_DOMAIN as string)

    // Default case
    return {
      redirect_uri: `${get_APP_URI_BASE()}/login`,
    };
  }

  console.log("IS READY", isReady);
  console.log("CLIENT ID", clientId);
  console.log("DOMAIN", domain);

  if (!isReady || !clientId || !domain) {
    return null;
  }

  console.log("AUTH0 PARAMS", auth0Params);

  return (
          <Auth0Provider
            domain={domain}
            //domain={getAuth0Domain() as string}
            //clientId={getAuth0ClientId() as string}
            clientId={clientId}

            authorizationParams={auth0Params}
          >
            <MessageReceiver />
            <AppLogic/>
            <AppRouter />
            {/* <div>
              <Chatbot/>
            </div> */}
          </Auth0Provider>
  )
}

if (getIsEdge()) {
  App = () => {
    return (
      <div id="app">
        <Provider store={store}>
          <AppRouter />
        </Provider>
      </div>
    );
  };
}

export default App;
