import { get_AI_URI_BASE, get_CDN_BASE_URL, get_CDN_ROOT_FOLDER, get_DASHBOARD_URI_BASE, get_IS_CDN77 } from "./configs/env";
import axios from "axios";
import { Library, Document, Theme, GetFirstGuidResponse, StartSessionResponse, PingSessionResponse, IResponse, PortalItem } from "./models/interfaces";
import { route } from "preact-router";
import { v4 as uuidv4 } from 'uuid';
import {
  setExplanator,
  setBackgroundImage,
  setBackgroundSize,
  setLogoSrc,
  setLogoHref,
  setQuestionsForExample,
  setContactUsDestinationLink,
  setFileRepositoryDestinationLink,
  setEnableContactUsDestinationLink,
  setEnableFileRepositoryDestinationLink,
  setEnableQuestionsForExample,
  setSearchBarPlaceHolder,
  setDisableFilters,
  setIsPrivate,
  setHighlightPdf,
  setHideFileName,
  setShowManyHeaders,
  setMaxPerSubject,
  setLoaded,
  setIsRTL,
  setYearRangeEnabled,
  setYearRangeEnabled2,
  setEnableArticlesLibrary,
  setEnableUpdatedArticles,
  setUpdatedArticlesExpireListCount, setBgPortraitImage, setRefineFlag , setShowRelatedDocuments
} from "./redux/slices/appearanceSlice";
import { setLoading } from "./redux/slices/chatbotSlice";
interface IAlias {
  workspaceId?: string;
  portalId?: string;
  workspaceAlias: string;
  portalAlias: string;
}
/**
 * general utils class
 */
export const Utils = {
  /**
   * initialize
   */
  init: () => {
    // put on window so that pdfjs and other external libs can use
    (window as any).printTimeAndDelta = Utils.printTimeAndDelta;
    (window as any)._timeStart = Date.now();
  },
  /**
   * print the time now and delta to previous print
   */
  printTimeAndDelta: () => {
    let timeNow = Date.now();
    // console.log(
    //   "%c Time now: " +
    //     timeNow +
    //     " delta: " +
    //     (timeNow - (window as any)._timeStart),
    //   "background: #222; color: #bada55"
    // );
    (window as any)._timeStart = timeNow;
  },
  //  `/workspace/${workspaceId}/portal/${portalId}/search?q=${input.current?.value}`
  ///workspace/${workspaceId}/kapp/${kappId}/search?q=${input.current?.value}`;
  // 
  getRouteParams: (routeTo: string) => {
    let parts = routeTo?.split("/");
    if (routeTo.indexOf("portal/") >= 0) {
      return {
        portalId: parts[4],
        workspaceId: parts[2],
        q: routeTo.substring(routeTo.indexOf("search?q=") + "search?q=".length),
      };
    } else if (routeTo.indexOf("search?q=") >= 0) {
      return {
        kappId: parts[4],
        workspaceId: parts[2],
        q: routeTo.substring(routeTo.indexOf("search?q=") + "search?q=".length),
      };
    } else return null;
  },

  /**
   *
   * @param text
   * @param cb
   * @returns
   */
  typeText(text: string, cb: Function) {
    var i = 0;
    if (text == "") {
      return;
    }
    let texRet = "";
    let words = text.split(" ");
    var intervalId = setInterval(function () {
      texRet += words[i] + " "; // text.charAt(i);
      cb(texRet);
      i++;
      if (i >= words.length) {
        clearInterval(intervalId);
        cb("*** stop ***");
      }
    }, 50);
  },

  /** get the theme from  the server */
  async getTheme(
    workspaceId: string,
    portalId: string,
    preview: boolean = false
  ) {
    try {
      const response = await axios(
        `${get_DASHBOARD_URI_BASE()}/portals/${portalId}/workspaces/${workspaceId}`
      );
      const portalInfo = response?.data?.data;
      let theme: Theme = portalInfo?.themes?.find(
        (theme: Theme) => theme.name === (preview ? "preview" : "publish")
      );

      return theme;
    } catch (ex) {
      console.error(
        `Cant access ${get_DASHBOARD_URI_BASE()}/portals/${portalId}/workspaces/${workspaceId}`
      );
    }

    return null;
  },

  getMuted() {
    let muted = Utils.localStorageGet("muted");
    if (muted == undefined) {
      muted = "true";
      Utils.localStorageSet("muted", "true");
    }
    return muted == "true";
  },

  setMuted(muted: boolean) {
    Utils.localStorageSet("muted", muted ? "true" : "false");
  },
  localStorageGet(key: string) {
    try {
      return localStorage.getItem(key);
    } catch (err) {
      console.error(err);
      return null;
    }
  },
  localStorageSet(key: string, value: string | null) {
    try {
      if (value == null) localStorage.removeItem(key);
      else localStorage.setItem(key, value || "");
    } catch (err) {
      console.error(err);
    }
  },

  uint8ToString(data: Uint8Array): string {
    var decoder = new TextDecoder();
    return decoder.decode(data);
  },

  stringToUint8(str: string): Uint8Array {
    var encoder = new TextEncoder();
    return encoder.encode(str);
  },

  /**
   * reset the profiling
   */
  resetTimeMeasurement: () => {
    (window as any)._timeStart = Date.now();
  },

  goHome: (alias: IAlias, kappId: string) => {
    const { workspaceAlias, portalAlias, workspaceId, portalId } = alias;
    let detail: string;
    if (workspaceAlias && portalAlias) {
      detail = `/${workspaceAlias}/${portalAlias}`;
    } else {
      detail =
        portalId && portalId?.length > 0
          ? `/workspace/${workspaceId}/portal/${portalId}/home`
          : `/workspace/${workspaceId}/kapp/${kappId}/home`;
    }

    (window as any).dispatchFnEvent(
      new CustomEvent("route-to", {
        detail,
      })
    );
  },
  setCookie: (cookieName: string, cookieValue: string, expirationDays = 30) => {
    var expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + expirationDays);

    var cookieString =
      encodeURIComponent(cookieName) +
      "=" +
      encodeURIComponent(cookieValue) +
      ";expires=" +
      expirationDate.toUTCString() +
      ";path=/";

    document.cookie = cookieString;
  },

  getCookieValue: (cookieName: string): string => {
    var name = cookieName + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var cookieArray = decodedCookie.split(";");

    for (var i = 0; i < cookieArray.length; i++) {
      var cookie = cookieArray[i];
      while (cookie.charAt(0) === " ") {
        cookie = cookie.substring(1);
      }
      if (cookie.indexOf(name) === 0) {
        return cookie.substring(name.length, cookie.length);
      }
    }
    return "";
  },

  localLogout: () => {
    Utils.setCookie("idToken", "", -1);

    Utils.routeToLogin(null);
  },

  secureGet: async (url: string, headers: any) => {
    return await axios.get(url, { headers });
  },

  /**
     * 
     * @returns 
     */
  getIdentifyingComponents: () => {

    // analyze the second part
    const getKappOrPortal: any = (parts: string[]) => {
      if (parts[2] == 'kapp') {
        console.error('No kappId allowed when route to login');
        return { kappId: parts[3], portalId: null };
      }

      else if (parts[2] == 'portal') {
        return { kappId: null, portalId: parts[3] };
      }
      console.error('unknown url - no kapp or portal in the url path ' + window.location.pathname);
      return { kappId: null, portalId: null };

    }

    // cut the pathname into parts
    let parts = window.location.pathname.split("/");
    // remove the first empty part
    parts.shift();
    let kpObj: any;
    switch (parts[0]) {
      case 'workspace':
        kpObj = getKappOrPortal(parts);
        return { workspaceId: parts[1], ...kpObj };
      case 'company':
        kpObj = getKappOrPortal(parts);
        return { workspaceId: parts[1], ...kpObj };
      default:
        return { workspaceAlias: parts[0], portalAlias: parts[1], workspaceId: null, portalId: null, kappId: null }
    }

  },

  /**
   * 
   * @returns 
   */
  buildPathname: () => {

    const { workspaceAlias, portalAlias, workspaceId, portalId, kappId } = Utils.getIdentifyingComponents();
    // build the pathname
    let pathname = '';
    if (workspaceId) pathname += `/workspace/${workspaceId}`;
    if (portalId) pathname += `/portal/${portalId}`;
    if (kappId) pathname += `/kapp/${kappId}`;
    if (workspaceAlias) pathname += `/${workspaceAlias}`;
    if (portalAlias) pathname += `/${portalAlias}`;
    return pathname;

  },
  /**
   * route to login with the current href
   */

  routeToLogin: (aliases: IAlias | null) => {
    let href = window.location.href;
    // encode href in base64
    href = btoa(href);
    href = encodeURIComponent(href);
    // if aliases, use them
    if (aliases) {
      route('/' + aliases.workspaceAlias + '/' + aliases.portalAlias + "/login/redirect/" + href)
    } else {
      // get the alias1/alias2 or workspace/id/portal/id from the url
      // if kappId, sends a error message but still route to login
      let pathname = Utils.buildPathname();
      route(pathname + "/login/redirect/" + href);
    }

  },

  checkUrlAnchor: (url: string): boolean => {
    return url.includes('#');
  },
};

// initialize
Utils.init();
const MAX_CHUNK_SIZE = 1024 * 10;

export const delay = async (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

class LRUCache {
  private maxEntries: number;
  private cache: { [key: string]: string };

  constructor(maxEntries: number) {
    this.maxEntries = maxEntries;
    this.cache = {};
    this.loadFromIndexedDB();
  }

  private loadFromIndexedDB(): void {
    const request = window.indexedDB.open("cacheDB", 1);

    request.onerror = () => {
      console.error("Error opening cache database.");
    };

    request.onsuccess = (event: Event) => {
      const db = (event.target as any).result;
      const transaction = db.transaction(["cache"], "readonly");
      const objectStore = transaction.objectStore("cache");

      objectStore.openCursor().onsuccess = (event: Event) => {
        const cursor = (event.target as any).result;
        if (cursor) {
          this.cache[cursor.key] = cursor.value;
          cursor.continue();
        }
      };
    };

    request.onupgradeneeded = (event: any) => {
      const db = event.target.result;
      if (!db.objectStoreNames.contains("cache")) {
        db.createObjectStore("cache");
      }
    };
  }

  get(key: string): Uint8Array | null {
    if (this.cache[key]) {
      this.moveToFront(key);
      return this.stringToAsciiByteArray(this.cache[key]);
      // return this.jsonToUint8Array(this.cache[key]);
    }
    return null;
  }

  put(key: string, value: Uint8Array): void {
    this.cache[key] = this.asciiByteArrayToString(value);
    // this.cache[key] = this.uint8ArrayToJson(value);
    // Check if cache has reached maximum capacity
    if (Object.keys(this.cache).length > this.maxEntries) {
      // Purge the least recently used item
      const oldestKey = Object.keys(this.cache)[0];
      delete this.cache[oldestKey];
    }

    this.saveToIndexedDB();
  }

  private saveToIndexedDB(): void {
    const request = window.indexedDB.open("cacheDB", 1);

    request.onerror = () => {
      console.error("Error opening cache database.");
    };

    request.onsuccess = (event: Event) => {
      const db = (event.target as any).result;
      const transaction = db.transaction(["cache"], "readwrite");
      const objectStore = transaction.objectStore("cache");

      objectStore.clear();

      for (const [key, value] of Object.entries(this.cache)) {
        objectStore.put(value, key);
      }
    };

    request.onupgradeneeded = (event: any) => {
      const db = event.target.result;
      if (!db.objectStoreNames.contains("cache")) {
        db.createObjectStore("cache");
      }
    };
  }

  private moveToFront(key: string): void {
    const value = this.cache[key];
    delete this.cache[key];
    this.cache[key] = value;
    this.saveToIndexedDB();
  }

  private uint8ArrayToJson(array: Uint8Array): string {
    const jsonArray = Array.from(array);
    return JSON.stringify(jsonArray);
  }

  private jsonToUint8Array(json: string): Uint8Array {
    const jsonArray = JSON.parse(json);
    return new Uint8Array(jsonArray);
  }

  private uint8ArrayToString(array: Uint8Array): string {
    const encoder = new TextDecoder("UTF-8");
    return encoder.decode(array).toString();
  }

  private stringToUint8Array(str: string): Uint8Array {
    const decoder = new TextEncoder();
    return decoder.encode(str);
  }

  // encode the string as ascii bytes
  private stringToAsciiByteArray(str: string): Uint8Array {
    const bytes = new Uint8Array(str.length);
    for (let i = 0; i < str.length; ++i) {
      bytes[i] = str.charCodeAt(i);
    }
    return bytes;
  }

  // decode the bytes as ascii string
  private asciiByteArrayToString(bytes: Uint8Array): string {
    let str = "";
    for (let i = 0; i < bytes.length; ++i) {
      str += String.fromCharCode(bytes[i]);
    }
    return str;
  }
}

// Usage
let pdfFileCache: any;
export const getPdfFileCache = () => {
  if (!pdfFileCache) {
    pdfFileCache = new LRUCache(3);
  }
  return pdfFileCache;
}
export const updateCdnUrlFromDotenv = (urlString: string): string => {
  try {
    const CDN_BASE_URL = get_CDN_BASE_URL();
    if (CDN_BASE_URL) {
      let url = new URL(urlString);
      let cdnBaseUrl = new URL(CDN_BASE_URL);
      url.protocol = cdnBaseUrl.protocol;
      url.host = cdnBaseUrl.host;
      url.pathname = cdnBaseUrl.pathname + url.pathname;
      // console.log(`url ${urlString} changing to ${url.toString()}`);
      return url.toString();
    } else return urlString;
  } catch (error) {
    return urlString;
  }
};

export const removeMultipleSlashes = (url: string): string => {
  try {
    return url.replace(/([^:]\/)\/+/g, "$1");
  } catch (error) {
    return url;
  }
};

export const getSassToken = async (
  workspaceId: string,
  portalId: string,
  userId: string | null,
  jwtUserIdToken: string | null,
  isEdge?: boolean | null,
) => {
  if (isEdge === true) {
    return ""
  }
  try {
    const sasTokenResponse = await axios(
      `${get_DASHBOARD_URI_BASE()}/flow/cdnuploader/st/workspace/${workspaceId}?userId=${userId}`,
      {
        headers: {
          Authorization: `Bearer ${jwtUserIdToken}`,
          "User-Id": userId,
          "Portal-Id": portalId,
        },
      }
    );
    if (sasTokenResponse?.data?.data?.error) {
      console.error(
        `/error/${500}/errorMessage/${sasTokenResponse?.data?.data?.error}`
      );
      return "";
    } else if (sasTokenResponse?.data?.data?.sasToken) {
      // all is ok, sas-token exist
      return sasTokenResponse?.data?.data?.sasToken;
    } else {
      // sas-token not exist
      console.error(
        `/error/${500}/errorMessage/something wrong with sass-token`
      );
      return "";
    }
  } catch (error) {
    console.error(error);
    return "";
  }
};

/**
* set the theme
* @param theme
*/
export const setTheme = (theme: Theme, dispatchFn: Function, workspaceId: string) => {
  try {
    if (theme) {
      if (theme.bgImage) {
        if (!get_IS_CDN77()) {
          let _bgImage = theme.bgImage;
          if (_bgImage.startsWith(workspaceId)) {
            _bgImage = _bgImage.replace(workspaceId, "");
          }
          dispatchFn(
            setBackgroundImage(
              `${get_CDN_BASE_URL()}/public/${workspaceId}${encodeURIComponent(
                _bgImage
              )}`
            )
          );
        }
        else {
          dispatchFn(
            setBackgroundImage(
              `${get_CDN_BASE_URL()}/${get_CDN_ROOT_FOLDER()}/${encodeURIComponent(
                theme.bgImage
              )}`
            )
          );
        }
      }
      if (theme.bgPortraitImage) {
        if (!get_IS_CDN77()) {
          let _bgPortraitImage = theme.bgPortraitImage;
          if (_bgPortraitImage.startsWith(workspaceId)) {
            _bgPortraitImage = _bgPortraitImage.replace(workspaceId, "");
          }
          dispatchFn(
            setBgPortraitImage(
              `${get_CDN_BASE_URL()}/public/${workspaceId}${encodeURIComponent(
                _bgPortraitImage
              )}`
            )
          );
        }
        else {
          dispatchFn(
            setBgPortraitImage(
              `${get_CDN_BASE_URL()}/${get_CDN_ROOT_FOLDER()}/${encodeURIComponent(
                theme.bgPortraitImage
              )}`
            )
          );
        }
      }

      if (theme.imageLayout) {
        dispatchFn(setBackgroundSize(`${theme.imageLayout}`));
      }
      if (theme.logoImage) {
        if (!get_IS_CDN77()) {
          let _logoImage = theme.logoImage;
          if (_logoImage.startsWith(workspaceId)) {
            _logoImage = _logoImage.replace(workspaceId, "");
          }
          dispatchFn(
            setLogoSrc(
              `${get_CDN_BASE_URL()}/public/${workspaceId}${encodeURIComponent(
                _logoImage
              )}`
            )
          );
        } else {
          dispatchFn(
            setLogoSrc(`${get_CDN_BASE_URL()}/${get_CDN_ROOT_FOLDER()}/${encodeURIComponent(theme.logoImage)}`))
        }
      }

      if (theme.logoUrl)
        dispatchFn(
          setLogoHref(
            `${theme.logoUrl.startsWith("http")
              ? theme.logoUrl
              : `https://${theme.logoUrl}`
            }`
          )
        );
      if (theme.questionsForExample)
        dispatchFn(setQuestionsForExample(theme.questionsForExample));
      if (theme.contactUsDestinationLink)
        dispatchFn(setContactUsDestinationLink(theme.contactUsDestinationLink));
      if (theme.fileRepositoryDestinationLink)
        dispatchFn(
          setFileRepositoryDestinationLink(
            theme.fileRepositoryDestinationLink
          )
        );
      dispatchFn(
        setEnableContactUsDestinationLink(
          !!theme.enableContactUsDestinationLink
        )
      );
      dispatchFn(
        setEnableFileRepositoryDestinationLink(
          !!theme.enableFileRepositoryDestinationLink
        )
      );
      dispatchFn(
        setEnableQuestionsForExample(!!theme.enableQuestionsForExample)
      );
      if (theme.searchBarPlaceHolder)
        dispatchFn(setSearchBarPlaceHolder(theme.searchBarPlaceHolder));
      dispatchFn(setDisableFilters(!!theme.disableFilters));
      dispatchFn(setRefineFlag(theme.refineResults));
      dispatchFn(setIsPrivate(!!theme.isPrivate));
      dispatchFn(setHighlightPdf(!!theme.highlightPdf));
      dispatchFn(setHideFileName(!!theme.hideFileName));
      dispatchFn(setShowManyHeaders(!!theme.showManyHeaders));
      dispatchFn(setShowRelatedDocuments(theme.showRelatedDocuments));
      if (theme.max_per_subject) dispatchFn(setMaxPerSubject(theme.max_per_subject));
      dispatchFn(setLoaded(true));

      if (theme.rtlenabled)
        dispatchFn(setIsRTL(theme.rtlenabled));

      if (theme.specialFeatures.toLowerCase() === "yearrange1") {
        dispatchFn(setYearRangeEnabled(true));
      }
      if (theme.specialFeatures.toLowerCase() === "yearrange2") {
        dispatchFn(setYearRangeEnabled2(true));
      }
      if (theme.enableArticlesLibrary) {
        dispatchFn(setEnableArticlesLibrary(true));
      }
      if (theme.enableUpdatedArticles) {
        dispatchFn(setEnableUpdatedArticles(true));
      }
      if (theme.updatedArticlesExpireListCount && theme.updatedArticlesExpireListCount > 0) {
        dispatchFn(setUpdatedArticlesExpireListCount(theme.updatedArticlesExpireListCount));
      }

    }
  } catch (error) {
    dispatchFn(setLoaded(false));
    console.error(error);
  }
};

// chronologicalOrder = true => descending order
export const sortSearchResultsChronologically = (searchResults: any[], chronologicalOrder: boolean): any[] => {
  return searchResults.sort((a: any, b: any) => {
    const aYear = a?.search?.tags?.find((tag: string) => tag?.length == 4 && !isNaN(Number(tag)));
    const bYear = b?.search?.tags?.find((tag: string) => tag?.length == 4 && !isNaN(Number(tag)));
    if (aYear && bYear) {
      if (chronologicalOrder) {
        return Number(aYear) - Number(bYear);
      } else {
        return Number(bYear) - Number(aYear);
      }
    }
    return 0;
  });
}

export const getArticlesForExample = async (
  workspaceId: string,
  portalId: string,
  mode: string,
  userId: string | null,
  token: string | null
): Promise<Library[]> => {
  try {
    const response = await axios(
      `${get_DASHBOARD_URI_BASE()}/libraries/web-app/all/workspace/${workspaceId}/portal/${portalId}/mode/${mode}?userId=${userId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'User-Id': userId,
          'Portal-Id': portalId
        }
      }
    );
    if (response?.data?.data && response?.data?.data?.length > 0) {
      return response.data.data as Library[];
    } else {
      console.error("No libraries found, or error in the response");
      return [];
    }
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const getUpdatedDocuments = async (
  workspaceId: string,
  portalId: string,
  userId: string | null,
  token: string | null,
  days: number
): Promise<Document[]> => {
  try {
    const response = await axios(
      `${get_DASHBOARD_URI_BASE()}/libraries/web-app/last-updated/workspace/${workspaceId}/portal/${portalId}?userId=${userId}&updatedArticlesExpireListCount=${days}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'User-Id': userId,
          'Portal-Id': portalId
        }
      }
    );
    if (response?.data?.data && response?.data?.data?.length > 0) {
      return response.data.data as Document[];
    } else {
      console.error("No libraries found, or error in the response");
      return [];
    }
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const getFirstGuidByDocGuid = async (
  workspaceId: string,
  portalId: string,
  kappId: string,
  docGuid: string,
  userId: string | null,
  token: string | null
): Promise<GetFirstGuidResponse | null> => {
  try {
    const response = await axios(
      `${get_DASHBOARD_URI_BASE()}/libraries/web-app/first-guid/workspace/${workspaceId}/kapp/${kappId}/docs/${docGuid}?userId=${userId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "User-Id": userId,
          "Portal-Id": portalId,
        },
      }
    );
    if (response?.data?.data && response?.data?.data?.guid) {
      return response.data.data as GetFirstGuidResponse;
    } else {
      console.error("No libraries found, or error in the response");
      return null;
    }
  } catch (error) {
    console.error(error);
    return null;
  }
};


export const checkPublicUserId = (): string => {

  const setCookie = (name: string, value: string): void => {
    const farFutureDate = new Date();
    farFutureDate.setFullYear(farFutureDate.getFullYear() + 100); // 100 years from now
    document.cookie = `${name}=${value}; expires=${farFutureDate.toUTCString()}; path=/; Secure; SameSite=None`;
  };

  const getCookie = (name: string): string => {
    const nameEQ = name + "=";
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i].trim();
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return "";
  };

  let userID = getCookie("korraUserID");
  if (userID?.length === 0) {
    userID = uuidv4();
    setCookie("korraUserID", userID);
    console.log("New user session created with ID:", userID);
  } else {
    console.log("Existing user session found with ID:", userID);
  }
  return userID;
};


export const startSession = async (
  workspaceId: string,
  portalId: string,
  userId: string | null,
  token: string | null,
  newChat: boolean,
  ip?: string,
): Promise<StartSessionResponse> => {
  const url = new URL(`${get_AI_URI_BASE()}/session_manager/start`);

  const reqObj = {
    workspaceId,
    portalId,
    userId,
    newChat,
  };

  const res = await fetch(<any>url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token as string}`,
      "User-Id": userId as string,
      "Portal-Id": portalId as string,
    },
    method: "POST",
    body: JSON.stringify(reqObj),
    mode: "cors",
  });

  const userResp: IResponse<StartSessionResponse> = await res.json();

  if (userResp.error) {
    throw new Error(userResp.error.message);
  } else {
    return userResp.data;
  }
};



export const PingSession = async (
  workspaceId: string,
  portalId: string,
  sessionId: string | null,
  userId: string | null,
  token: string | null,
  newChat: boolean,
  ip?: string,
): Promise<PingSessionResponse | null> => {
  try {

    const url = new URL(`${get_AI_URI_BASE()}/session_manager/ping`);
    const reqObj = {
      workspaceId,
      portalId,
      userId,
      newChat,
    };

    const response = await fetch(<any>url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token as string}`,
        "User-Id": userId as string,
        "Portal-Id": portalId as string,
      },
      method: "POST",
      body: JSON.stringify(reqObj),
      mode: "cors",
    });

    const userResp: IResponse<PingSessionResponse> = await response.json();

    if (userResp.error) {
      throw new Error(userResp.error.message);
    } else {
      return userResp.data;
    }
  } catch (error) {
    console.error(error);
    return null;
  }
};

export function hashCode(str: string) {
  return Array.from(str).reduce((s, c) => Math.imul(31, s) + c.charCodeAt(0) | 0, 0).toString();
}

export const getPortal = async (
  workspaceId: string,
  portalId: string,
  userId: string | null,
  token: string | null,
  dispatch: any
): Promise<PortalItem> => {
  const url = `${get_DASHBOARD_URI_BASE()}/portals/${portalId}/workspaces/${workspaceId}?userId=${userId}`;
  const res = await fetch(<any>url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    method: "GET",
    mode: "cors",
  });

  const portal: IResponse<PortalItem> = await res.json();
  if (portal.error) {
    dispatch(setLoading(false))
    throw new Error(portal.error.message);
  } else {
    dispatch(setLoading(true))
    return portal.data;
  }
};

export const toKorraDateFormat = (date: Date): string => {
  const day = date.getDate().toString().padStart(2, '0');
  const month = date.toLocaleDateString("en-US", { month: "short" });
  const year = date.getFullYear().toString().slice(-2); // Last two digits of the year
  return `${day}-${month}-${year}`;
};