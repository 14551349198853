import { createContext, FunctionalComponent, h } from "preact";
import { useContext, useEffect, useState } from "preact/hooks";
import { IGoogleSpeechResult, ISpeechDataEvent } from "../models/interfaces";
import { useSocketContext } from "./SocketContext";

export interface IStream {
    streamText: string;
    startStream: () => void;
    endStream: () => void;
    isFinal: boolean;
}

export const StreamContext = createContext<IStream>({
    streamText: "",
    startStream: () => {},
    endStream: () => {},
    isFinal: false,
});

export const useStreamContext = () => {
    return useContext(StreamContext);
};

const StreamProvider: FunctionalComponent = ({ children }) => {
    const [streamText, setStreamText] = useState<string>("");
    const [stream, setStream] = useState<MediaStream | null>(null);
    const [context, setContext] = useState<AudioContext | null>(null);
    const [source, setSource] = useState<MediaStreamAudioSourceNode | null>(
        null
    );
    const [processor, setProcessor] = useState<ScriptProcessorNode | null>(
        null
    );
    const [isReady, setIsReady] = useState<boolean>(false);
    const [isFinal, setIsFinal] = useState<boolean>(false);

    const { isSocketConnected, socket, isSpeechEnd, speechResaults } =
        useSocketContext();

    //UTIL FUNCTIONS
    const downsampleBuffer = (
        buffer: Float32Array,
        sampleRate: number,
        outSampleRate: number
    ) => {
        if (outSampleRate == sampleRate) {
            return buffer;
        }
        if (outSampleRate > sampleRate) {
            throw "downsampling rate show be smaller than original sample rate";
        }
        var sampleRateRatio = sampleRate / outSampleRate;
        var newLength = Math.round(buffer.length / sampleRateRatio);
        var result = new Int16Array(newLength);
        var offsetResult = 0;
        var offsetBuffer = 0;
        while (offsetResult < result.length) {
            var nextOffsetBuffer = Math.round(
                (offsetResult + 1) * sampleRateRatio
            );
            var accum = 0,
                count = 0;
            for (
                var i = offsetBuffer;
                i < nextOffsetBuffer && i < buffer.length;
                i++
            ) {
                accum += buffer[i];
                count++;
            }

            result[offsetResult] = Math.min(1, accum / count) * 0x7fff;
            offsetResult++;
            offsetBuffer = nextOffsetBuffer;
        }
        return result.buffer;
    };

    const startStream = async () => {
        if (isSocketConnected) {
            const mediaStream = await navigator.mediaDevices.getUserMedia({
                video: false,
                audio: true,
            });
            setStream(mediaStream);
        }
    };

    const endStream = async () => {
        setStreamText("");
        socket.emit("endGoogleCloudStream", "");
        const track = stream?.getTracks()[0];
        if (!track) {
            return console.log(
                "warning: no track in stopRecognize speech. probably mic is blocked"
            );
        }
        track.stop();

        if (processor && source && context) {
            source.disconnect(processor);
            processor.disconnect(context.destination);
            const res = await context?.close().then((res) => {
                setSource(null);
                setProcessor(null);
                setContext(null);
                setStream(null);
                setIsReady(false);
                // setIsRemoveStream(false)
            });
        } else {
            // setIsFinal(true);
            console.warn("CHECK EXEPTION");
        }
    };

    useEffect(() => {
        if (isSpeechEnd) {
            endStream();
        }
    }, [isSpeechEnd]);

    useEffect(() => {
        if (!isReady && stream && isSocketConnected) {
            //logic connect to audio
            const audioContext = new AudioContext();
            setContext(audioContext);
        } else {
            // setIsFinal(true);
            console.warn("exeption in context");
        }
    }, [stream]);

    useEffect(() => {
        if (!isReady && context && stream && isSocketConnected) {
            const mediaSource = context.createMediaStreamSource(stream);
            setSource(mediaSource);
        } else {
            setIsFinal(true);
            console.warn("exeption in source");
        }
    }, [context]);

    useEffect(() => {
        if (!isReady && source && context && stream && isSocketConnected) {
            const scriptProcessor = context.createScriptProcessor(1024, 1, 1);
            setProcessor(scriptProcessor);
        } else {
            // setIsFinal(true);
            console.warn("exeption in processor");
        }
    }, [source]);

    useEffect(() => {
        if (
            !isReady &&
            processor &&
            source &&
            context &&
            stream &&
            isSocketConnected
        ) {
            const scriptProcessor = context.createScriptProcessor(1024, 1, 1);
            setProcessor(scriptProcessor);
            setIsReady(true);
        } else {
            // setIsFinal(true);
            console.warn("exeption in processor");
        }
    }, [processor]);

    useEffect(() => {
        const processLogic = (e: AudioProcessingEvent) => {
            // Do something with the data, e.g. convert it to WAV
            const left = e.inputBuffer.getChannelData(0);
            const left16 = downsampleBuffer(left, 44100, 16000);
            socket.emit("binaryData", left16);
        };

        if (isReady && source && processor && context) {
            socket.emit("startGoogleCloudStream", ""); //init socket Google Speech Connection
            source.connect(processor);
            processor.connect(context.destination);

            processor.addEventListener("audioprocess", processLogic);
        }

        return () => {
            processor?.removeEventListener("audioprocess", processLogic);
        };
    }, [isReady]);

    useEffect(() => {
        if (typeof speechResaults?.alternatives[0].transcript === "string") {
            //need to think about confidence level
            // if (
            //     speechResaults.isFinal &&
            //     speechResaults.alternatives[0].confidence > 0.8
            // ) {
            //     setStreamText(
            //         speechResaults?.alternatives[0].transcript.toString()
            //     );
            // }
            setStreamText(
                speechResaults?.alternatives[0].transcript.toString()
            );
            setIsFinal(speechResaults.isFinal);
            // console.log(
            //     `streamText: ${speechResaults?.alternatives[0].transcript.toString()} isFinal: ${
            //         speechResaults.isFinal
            //     }`
            // );
        }
    }, [speechResaults]);

    const exposedApi: IStream = {
        streamText,
        startStream,
        endStream,
        isFinal,
    };

    return (
        <StreamContext.Provider value={exposedApi}>
            {children}
        </StreamContext.Provider>
    );
};

export default StreamProvider;
