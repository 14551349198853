import { configureStore } from "@reduxjs/toolkit";
import appearanceSlice from "./slices/appearanceSlice";
import controlsSlice from "./slices/controlsSlice";
import globalsSlice from "./slices/globalsSlice";
import searchSlice from "./slices/searchSlice";
import aliasSlice from "./slices/aliasSlice";
import authSlice from "./slices/authSlice";
import chatbotSlice from "./slices/chatbotSlice";
//import { getDefaultMiddleware } from '@reduxjs/toolkit';

const store = configureStore({
    reducer: {
        appearance: appearanceSlice,
        controls: controlsSlice,
        globals: globalsSlice,
        search: searchSlice,
        alias: aliasSlice,
        auth: authSlice,
        chatbot:chatbotSlice
    },
});

type RootState = ReturnType<typeof store.getState>;
type AppDispatch = typeof store.dispatch;

export { store, RootState, AppDispatch };
