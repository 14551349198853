import { createContext, FunctionalComponent, h } from "preact";
import { useContext, useEffect, useState } from "preact/hooks";
import { io, Socket } from "socket.io-client";
import { get_API_URI_BASE } from "../configs/env";
import { IGoogleSpeechResult, ISpeechDataEvent } from "../models/interfaces";

export interface ISocket {
  isSocketConnected: boolean;
  speechResaults: IGoogleSpeechResult | null;
  isSpeechEnd: boolean;
  socket: Socket;
}

let socket:any = null;
if (!!get_API_URI_BASE()) {
  socket = io(get_API_URI_BASE() || "", {
  path: "/speech-server/socket.io",
});
}


export const SocketContext = createContext<ISocket>({
  isSocketConnected: false,
  speechResaults: null,
  isSpeechEnd: false,
  socket,
});

export const useSocketContext = () => {
  return useContext(SocketContext);
};

const SocketProvider: FunctionalComponent = ({ children }) => {
  const [isSocketConnected, setIsSocketConnected] = useState<boolean>(
    socket.connected
  );
  const [speechResaults, setSpeechResaults] =
    useState<IGoogleSpeechResult | null>(null);
  const [isSpeechEnd, setIsSpeechEnd] = useState<boolean>(false);

  //socket io logic
  useEffect(() => {
    socket.on("connect", () => {
      setIsSocketConnected(true);
    });

    socket.on("disconnect", (reason:any) => {
      setIsSocketConnected(false);
      //   if (reason === "io server disconnect") {
      //     // the disconnection was initiated by the server, you need to reconnect manually
      //     socket.connect();
      //   }
      // else the socket will automatically try to reconnect
    });

    socket.on("speechData", (data: ISpeechDataEvent) => {
      setIsSpeechEnd(false);
      if (data?.results[0] && data.results[0]?.alternatives[0]) {
        setSpeechResaults(data.results[0]);
      }
    });

    socket.io.on("reconnect", () => {});

    socket.on("speechEnded", async () => {
      setIsSpeechEnd(true);
    });

    return () => {
      socket.off("connect");
      socket.off("disconnect");
      socket.off("speechData");
      socket.off("speechEnded");
    };
  }, []);

  const exposedApi: ISocket = {
    isSocketConnected,
    speechResaults,
    isSpeechEnd,
    socket,
  };

  return (
    <SocketContext.Provider value={exposedApi}>
      {children}
    </SocketContext.Provider>
  );
};

export default SocketProvider;
