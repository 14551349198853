import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const aliasSlice = createSlice({
    name: "alias",
    initialState: {
        workspaceId: "",
        portalId: "",
        workspaceAlias: "",
        portalAlias: "",
        mode:"",
    },
    reducers: {
        setWorkspaceId: (state, action: PayloadAction<string>) => {
            state.workspaceId = action.payload;
        },
        setPortalId: (state, action: PayloadAction<string>) => {
            state.portalId = action.payload;
        },
        setWorkspaceAlias: (state, action: PayloadAction<string>) => {
            state.workspaceAlias = action.payload;
        },
        setPortalAlias: (state, action: PayloadAction<string>) => {
            state.portalAlias = action.payload;
        },
        setAliasMode: (state, action: PayloadAction<string>) => {
            state.mode = action.payload;
        },
    },
});

export const {
    setWorkspaceId,
    setPortalId,
    setWorkspaceAlias,
    setPortalAlias,
    setAliasMode,
} = aliasSlice.actions;
export default aliasSlice.reducer;
