import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  ChatThemeItem
} from "../../models/interfaces";

const chatbotSlice = createSlice({
  name: "appearance",
  initialState: {
    chabotThemeSettings: null as ChatThemeItem | null,
    loaded: null as boolean | null,
    personaMode: "[Free text]" as string,
  },
  reducers: {
    setChatThemeSetting: (state, action: PayloadAction<ChatThemeItem>) => {
      state.chabotThemeSettings = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loaded = action.payload;
    },
    setPersonaMode: (state, action: PayloadAction<string>) => {
      state.personaMode = action.payload;
    },
  },
});

export const {
  setChatThemeSetting,
  setLoading,
  setPersonaMode,
} = chatbotSlice.actions;
export default chatbotSlice.reducer;