/**
 * This file contains all the environment variables that are used in the application.
 */
const auth0Envs: any = {
  "/liorsspace/amttest": {
    audience: "korra-amt.us.auth0.com",
    domain: "auth0-amt.korra.ai",
    clientId: "6uBurEECX3kUTGXDfduOkdW0A6rfG1Ts",
  },
  "/amtprivate/amtku": {
    audience: "korra-amt.us.auth0.com",
    domain: "auth0-amt.korra.ai",
    clientId: "6uBurEECX3kUTGXDfduOkdW0A6rfG1Ts",
  },
  "/amt/arksym3": {
    audience: "korra-amt.us.auth0.com",
    domain: "auth0-amt.korra.ai",
    clientId: "6uBurEECX3kUTGXDfduOkdW0A6rfG1Ts",
  },
  "/amt/semvisionserviceconsole": {
    audience: "korra-amt.us.auth0.com",
    domain: "auth0-amt.korra.ai",
    clientId: "6uBurEECX3kUTGXDfduOkdW0A6rfG1Ts",
  },
  "/amt/enlightservice": {
    audience: "korra-amt.us.auth0.com",
    domain: "auth0-amt.korra.ai",
    clientId: "6uBurEECX3kUTGXDfduOkdW0A6rfG1Ts",
  },
  "/amt/provisionservice": {
    audience: "korra-amt.us.auth0.com",
    domain: "auth0-amt.korra.ai",
    clientId: "6uBurEECX3kUTGXDfduOkdW0A6rfG1Ts",
  },
  "/wedge/wedgeknowledgebase": {
    clientId: "K00yRyB92IwQqPABvSB0G8L0jRpjIrOG",
    audience: "korra.us.auth0.com",
    domain: "auth0-second.korra.ai",
  },
  "/wedge/externalwedgeknowledgebase": {
    clientId: "K00yRyB92IwQqPABvSB0G8L0jRpjIrOG",
    audience: "korra.us.auth0.com",
    domain: "auth0-second.korra.ai",
  },
  "/wedge/wedgeknowledgebasedevint": {
    clientId: "lS7PosSDYsFOjT0GBZfNdAe3hp0TE3NW",
    audience: "korra.us.auth0.com",
    domain: "auth0-second.korra.ai",
  },
  "/wedge/devwedgeknowledgebasedev": {
    clientId: "lS7PosSDYsFOjT0GBZfNdAe3hp0TE3NW",
    audience: "korra.us.auth0.com",
    domain: "auth0-second.korra.ai",
  },
  "/sspace0/sso": {
    clientId: "dMXGnBvWN3miWXd1sn8E0SIjj3nSbnm0",
    audience: "korra.us.auth0.com",
    domain: "auth0-second.korra.ai",
  },
  "/thetarayspace/onboarding": {
    clientId: "dMXGnBvWN3miWXd1sn8E0SIjj3nSbnm0",
    audience: "korra.us.auth0.com",
    domain: "auth0-second.korra.ai",
  },
};

export const getAppPath = () => {
  const path = window.location.pathname;

  if (path.startsWith("/wedge/devwedgeknowledgebasedev")) {
    return "/wedge/devwedgeknowledgebasedev";
  }

  if (path.startsWith("/wedge/wedgeknowledgebasedevint")) {
    return "/wedge/wedgeknowledgebasedevint";
  }

  // for company-specific auth0 applications, we need to have the redirect_uri starts with the company-specific path
  for (const [key, value] of Object.entries(auth0Envs)) {
    if (window.location.pathname.startsWith(key)) {
      console.log("using auth0 envs for ", key);
      return key;
    }
  }

  return "";
};

/**
 *
 * @returns the auth0 envs for the current url
 */
const getAuth0Envs: any = () => {
  // const { protocol, host, path } = analyzeUrl(window.location.origin);
  // loop over the auth0 envs and find the one that matches the current url
  //
  for (const [key, value] of Object.entries(auth0Envs)) {
    if (window.location.pathname.startsWith("/wedge/devwedgeknowledgebasedev")) {
      return auth0Envs["/wedge/devwedgeknowledgebasedev"];
    }

    if (window.location.pathname.startsWith("/wedge/wedgeknowledgebasedevint")) {
      return auth0Envs["/wedge/wedgeknowledgebasedevint"];
    }

    if (window.location.pathname.startsWith("/wedge/wedgeknowledgebasedevint")) {
      return auth0Envs["/wedge/wedgeknowledgebasedevint"];

    }

    if (window.location.pathname.startsWith(key)) {
      return value;
    }
  }

  return {
    clientId: process.env.AUTH0_CLIENT_ID,
    audience: process.env.AUTH0_AUDIENCE,
    domain: process.env.AUTH0_DOMAIN,
  };
};
export const get_WorkSpaces_ROW_ID = (): string | undefined => {
  return process.env.ROW_WORKSPACES;
};

export const get_APP_URI_BASE = (): string | undefined => {
  if (process.env.IS_EDGE == "true") return window.location.origin;
  return process.env.APP_URI_BASE;
};

export const get_AI_URI_BASE = (): string | undefined => {
  if (process.env.IS_EDGE == "true") return `${window.location.origin.replace(":8999","")}/ai`;
  return process.env.AI_URI_BASE;
};
export const get_SEARCH_URI_BASE = (): string | undefined => {
  if (process.env.IS_EDGE == "true") return `${window.location.origin.replace(":8999","")}/search`;
  return process.env.SEARCH_URI_BASE;
};
export const get_API_URI_BASE = (): string | undefined => {
  return process.env.API_URI_BASE;
};
export const get_DASHBOARD_URI_BASE = (): string | undefined => {
  if (process.env.IS_EDGE == "true") return `${window.location.origin.replace(":8999","")}/dashboard`;
  return process.env.DASHBOARD_URI_BASE;
};
export const get_STATS_URI_BASE = (): string | undefined => {
  if (process.env.IS_EDGE == "true") return `${window.location.origin.replace(":8999","")}/stats`;
  return process.env.STATS_URI_BASE;
};
export const get_CDN_BASE_URL = (): string | undefined => {
  if (process.env.IS_EDGE == "true") return `${window.location.origin.replace(":8999","")}/ai/local_cdn/route`;
  return process.env.CDN_BASE_URL;
};
export const get_CDN_NAME = (): string | undefined => {
  return process.env.CDN_NAME;
};
export const get_IS_CDN77 = (): boolean | undefined => {
  return process.env.CDN_NAME == "CDN77";
};
export const get_CDN_ROOT_FOLDER = (): string | undefined => {
  return process.env.CDN_ROOT_FOLDER;
};
export const get_EXPLANATOR_URI_BASE = (): string | undefined => {
  return process.env.EXPLANATOR_URI_BASE;
};
export const get_AUTH_HOST = (): string | undefined => {
  if (process.env.IS_EDGE == "true") return `${window.location.origin.replace(":8999","")}/auth`;
  return process.env.AUTH_HOST;
};
export const getAuth0Audience = (): string | undefined => {
  return (getAuth0Envs() as any).audience;
};
export const getAuth0Domain = (): string | undefined => {
  return (getAuth0Envs() as any).domain;
};
export const getAuth0ClientId = (): string | undefined => {
  return (getAuth0Envs() as any).clientId;
};
export const getSearchNumScores = (): string | undefined => {
  return process.env.SEARCH_NUM_SCORES;
};
export const getSearchFirstN = (): string | undefined => {
  return process.env.SEARCH_FIRST_N;
};
export const getIsEdge = (): boolean | undefined => {
  return process.env.IS_EDGE == "true";
};
export const getIsLocalIdentity = (): boolean | undefined => {
  return process.env.IS_LOCAL_IDENTITY == "true";
};
