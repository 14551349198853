import { User } from "@auth0/auth0-react";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IUser } from "../../models/interfaces";

const authSlice = createSlice({
    name: "auth",
    initialState: {
        auth0User: undefined as User | undefined,
        korraUser: null as IUser | null,
        azureSasToken: "",
        azureSasTokenTimestamp: 0,
        haveSSO: undefined as boolean | undefined
    },
    reducers: {
        setAuth0User: (state, action: PayloadAction<User>) => {
            state.auth0User = action.payload;
        },
        setAuth0SSO: (state, action: PayloadAction<boolean>) => {
            state.haveSSO = action.payload;
        },
        setKorraUser: (state, action: PayloadAction<IUser>) => {
            state.korraUser = action.payload;
        },
        setAzureSasToken: (state, action: PayloadAction<string>) => {
            state.azureSasToken = action.payload;
        },
        setAzureSasTokenTimestamp: (state, action: PayloadAction<number>) => {
            state.azureSasTokenTimestamp = action.payload;
        },
    },
});

export const { setAuth0User, setKorraUser, setAzureSasToken, setAzureSasTokenTimestamp, setAuth0SSO } = authSlice.actions;
export default authSlice.reducer;
