import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const globalsSlice = createSlice({
  name: "globals",
  initialState: {
    windowLoaded: false,
    experienceName: null as string | null,
    searchResults: null as any,
    jwtUserIdToken: null as string | null,
    userId: null as string | null,
    clientIp: null as string | null,
    userIdRan:false,
    sessionId:null as string|null,
    sessionTime:null as string|null,
  },
  reducers: {
    setWindowLoaded: (state, action: PayloadAction<boolean>) => {
      state.windowLoaded = action.payload;
    },
    setExperienceName: (state, action: PayloadAction<string>) => {
      state.experienceName = action.payload;
    },
    setSearchResults: (state, action: PayloadAction<any>) => {
      state.searchResults = action.payload;
    },
    setJWTUserIdToken: (state, action: PayloadAction<string>) => {
      state.jwtUserIdToken = action.payload;
    },
    setUserId: (state, action: PayloadAction<string>) => {
      state.userId = action.payload;
    },
    setClientIp: (state, action: PayloadAction<string>) => {
      state.clientIp = action.payload;
    },
    setUserIdRan: (state, action: PayloadAction<boolean>) => {
      state.userIdRan = action.payload;
    },
    setSessionId: (state, action: PayloadAction<string>) => {
      state.sessionId = action.payload;
    },
    setSessionTime: (state, action: PayloadAction<string>) => {
      state.sessionTime = action.payload;
    },
  },
});

export const {
  setWindowLoaded,
  setExperienceName,
  setSearchResults,
  setJWTUserIdToken,
  setUserId,
  setClientIp,
  setUserIdRan,
  setSessionId,
  setSessionTime,
} = globalsSlice.actions;
export default globalsSlice.reducer;
